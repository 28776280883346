
import { Component, Watch, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import {
  isEmpty, compact, concat, flatten, map, values,
} from 'lodash'

import store from '@/store'
import { l, localeFromPath, formatDate } from '@/utils/helpers'

import FourOhFour from '@/components/molecules/FourOhFour.vue'
import MoviePlayer from '@/components/atoms/MoviePlayer.vue'
import FilmCollectionsCarousel from '@/components/molecules/FilmCollectionsCarousel.vue'
import FilmsCarousel from '@/components/molecules/FilmsCarousel.vue'
import Button from '@/components/atoms/Button.vue'
import Date from '@/components/atoms/Date.vue'
import ContentSection from '@/components/organisms/ContentSection.vue'
import SocialShare from '@/components/molecules/SocialShare.vue'
import Loader from '@/components/atoms/Loader.vue'

import conf from '../../configuration'

@Component({
  components: {
    FourOhFour,
    MoviePlayer,
    FilmCollectionsCarousel,
    FilmsCarousel,
    Button,
    ContentSection,
    Date,
    SocialShare,
    Loader,
  },
  computed: mapState({
    contentNotFound: (state: any) => (
      state.status === 404
    ),
    film: (state: any) => (
      (state.active && state.active.post) ? state.active.post.post : undefined
    ),
    videos: (state: any) => (
      (state.active
        && state.active.post
        && state.active.post.post
        && !isEmpty(state.active.post.post.videos)
        && state.active.post.post.contentType === 'film')
        ? flatten(map(state.active.post.post.videos, (v: any) => values(v)))
        : undefined
    ),
    films: (state: any) => (
      (state.active
        && state.active.post
        && !isEmpty(state.active.post.films))
        ? state.active.post.films
        : undefined
    ),
  }),
  metaInfo() {
    const {
      title, description, locale, imageAlt, thumbnail,
    } = this.$store.getters.activePost || {}
    let thumbnailPath = ''
    if (thumbnail && typeof thumbnail === 'string') {
      thumbnailPath = thumbnail.startsWith('/') ? thumbnail : `/${thumbnail}`
    }
    return {
      title,
      meta: [
        { name: 'description', content: description },
        // Open graph
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:image',
          content: `${conf.media.baseUrl}${conf.media.sourcePath}/${conf.media.namespace}${thumbnailPath}`,
        },
        {
          property: 'og:image:alt',
          content: imageAlt,
        },
        // Twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:summary', content: description },
        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: description },
        { property: 'twitter:url', content: window.location.href },
        {
          property: 'twitter:image',
          content: `${conf.media.baseUrl}${conf.media.sourcePath}/${conf.media.namespace}${thumbnailPath}`,
        },
      ],
      htmlAttrs: {
        lang: locale,
      },
      link: [
        {
          rel: 'canonical',
          href: locale === 'en' && !this.$route.path.includes('/en/')
            ? `${conf.url}${this.$route.path.replace(/\/$/, '')}/en/`
            : `${conf.url}${this.$route.path.replace(/\/$/, '')}`,
        },
      ],
    }
  },
})
export default class Film extends Vue {
  private locale: string = localeFromPath(this.$route.path || '/');

  private displayDownloads: boolean = false;

  private loading: boolean = false;

  toggleDownloads() {
    this.displayDownloads = !this.displayDownloads
  }

  get film() {
    return this.$store.state.active?.post?.post
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.loading = true
    this.locale = localeFromPath(this.$route.path || '/')

    // Get all Film Collections
    if (isEmpty(this.$store.getters.filmCollections)) {
      store.dispatch('fetch', `/?contentType=filmCollection&&locale=${this.locale}`)
        .then((result: any) => {
          try {
            store.dispatch('set', { key: 'content.filmCollections', data: result })
          } catch (err) {
            document.dispatchEvent(new Event('custom-render-trigger'))
          }
        })
        .catch((error: string) => {
          document.dispatchEvent(new Event('custom-render-trigger'))
          throw new Error(error)
        })
        .finally(() => {
          // Ensure loading is set to false after fetching film collections
          this.loading = false
        })
    } else {
      this.loading = false // Set loading to false if film collections are already present
    }

    if (this.$route && this.$route.params && this.$route.params.slug) {
      store.dispatch('fetch', `/?slug=${this.$route.params.slug}&&locale=${this.locale}`)
        .then((result: any) => {
          if (result && result[0]) {
            try {
              store.dispatch('set', { key: 'active.post.post', data: result[0] })

              let filmCollectionUuid: string = ''
              if (result[0].contentType === 'filmCollection') {
                try {
                  store.dispatch('set', { key: 'active.filmCollectionUuid', data: result[0].uuid })
                  filmCollectionUuid = result[0].uuid
                } catch (err) {
                  document.dispatchEvent(new Event('custom-render-trigger'))
                }
              } else {
                try {
                  store.dispatch('set', { key: 'active.filmCollectionUuid', data: result[0].filmCollection })
                } catch (err) {
                  document.dispatchEvent(new Event('custom-render-trigger'))
                }
                try {
                  store.dispatch('set', { key: 'active.filmUuid', data: result[0].uuid })
                  filmCollectionUuid = result[0].filmCollection
                } catch (err) {
                  document.dispatchEvent(new Event('custom-render-trigger'))
                }
              }
              store.dispatch('fetch', `/?contentType=film&&locale=${this.locale}&&filmCollection=${filmCollectionUuid}`)
                .then((films: any) => {
                  if (films) {
                    try {
                      store.dispatch('set', { key: 'active.post.films', data: films })

                      if (this.$store.state
                                            && this.$store.state.active
                                            && this.$store.state.active.filmCollectionUuid
                                            && this.$store.state.active.post
                                            && this.$store.state.active.post.films
                                            && this.$store.state.active.post.post) {
                        setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 6000)
                      }
                    } catch (err) {
                      document.dispatchEvent(new Event('custom-render-trigger'))
                    }
                  }
                })
                .catch((error: string) => {
                  document.dispatchEvent(new Event('custom-render-trigger'))
                })
                .finally(() => {
                  this.loading = false
                })
            } catch (err) {
              document.dispatchEvent(new Event('custom-render-trigger'))
            }
          }
        })
        .catch((error: string) => {
          try {
            store.dispatch('set', { key: 'status', data: 404 })
          } catch (err) {
            document.dispatchEvent(new Event('custom-render-trigger'))
          }
          setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.loading = false // Set loading to false if route params are not present
    }
  }

  // Ensure that all necessary methods are present and defined as class methods
  l(key: string, locale: string) {
    return l(key, locale)
  }

  formatDate(date: string, locale: string) {
    return formatDate(date, locale)
  }

  localeFromPath(path: string) {
    return localeFromPath(path)
  }

  getRelatedContentIds(post: any) {
    if (post) {
      return compact(concat(
        post.relatedContent,
        post.relatedFilms,
        post.relatedDatabases,
        post.relatedTools,
        post.relatedInterviews,
        post.relatedGuides,
        post.relatedCurricula,
      ))
    }
    return []
  }

  get relatedContentIds() {
    return this.getRelatedContentIds(this.film)
  }
}
