

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import path from 'path'
import _ from 'lodash'
import { imagePath } from '@/utils/helpers'
import playIcon from '@/assets/svg/play.svg'

@Component({
  methods: {
    imagePath,
    playIcon,
  },
})
export default class MoviePlayer extends Vue {
  @Prop({ required: true }) private width!: number

  @Prop() private height!: number;

  @Prop() private videos!: string[]

  @Prop() private poster!: string

  @Watch('videos')
  onUuidChange(newVideos: string[], oldVideos: string[]) {
    this.videos = newVideos
  }

  mimetype(videoUri: string) {
    if (videoUri) {
      return `video/${_.replace(path.extname(videoUri), '.', '')}`
    }
    return ''
  }

  // Get image width based on viewport size
  getImageWidth() {
    return window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  }
}
