
import { Component, Vue } from 'vue-property-decorator'
import FilmCard from '@/components/atoms/FilmCard.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import Loader from '@/components/atoms/Loader.vue'

@Component({
  components: {
    FilmCard,
    VueSlickCarousel,
    Loader,
  },
})
export default class FilmCarousel extends Vue {
  get films(): any[] {
    return this.$store.state.active.post.films || []
  }

  get activePost(): any {
    return this.$store.state.active.post.post || null
  }

  get shouldEnableCenterMode(): boolean {
    return this.films.length <= 4
  }

  get slickOptions(): any {
    return {
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      centerMode: this.shouldEnableCenterMode,
      focusOnSelect: true,
      adaptiveHeight: false,
      infinite: false,
      arrows: true,
      centerPadding: '50px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
  }

  get currentSlideIndex(): number {
    return this.films.findIndex(film => film.uuid === this.activePost?.uuid) || 0
  }

  get computedInitialSlide(): number {
    const width = window.innerWidth
    if (width <= 479) {
      return this.currentSlideIndex
    } if (width <= 599) {
      return Math.max(this.currentSlideIndex - 1, 0)
    } if (width <= 1023) {
      return Math.max(this.currentSlideIndex - 2, 0)
    }
    return Math.max(this.currentSlideIndex - 2, 0) // Default case for larger screens
  }

  isActive(index: number): boolean {
    return (
      this.activePost?.uuid === this.films[index]?.uuid
      || (this.films[index]?.uuid === this.films[0]?.uuid && this.activePost?.contentType === 'filmCollection')
    )
  }
}
