
import { Component, Vue } from 'vue-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import FilmCollectionCard from '@/components/atoms/FilmCollectionCard.vue'

@Component({
  components: {
    FilmCollectionCard,
    VueSlickCarousel,
  },
})
export default class FilmCollectionsCarousel extends Vue {
  get filmCollections(): any[] {
    return this.$store.state.content.filmCollections
  }

  get activePost(): any {
    return this.$store.state.active.post.post
  }

  // Slick carousel options
  get slickOptions(): any {
    return {
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      centerMode: false,
      focusOnSelect: true,
      adaptiveHeight: true,
      infinite: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
  }

  get currentSlideIndex(): number {
    if (this.filmCollections && this.filmCollections.length > 0 && this.activePost) {
      for (let i = 0; i < this.filmCollections.length; i += 1) {
        const c = this.filmCollections[i]
        if (this.activePost.uuid === c.uuid || this.activePost.filmCollection === c.uuid) {
          return i
        }
      }
      return 0
    }
    return 0
  }
}
