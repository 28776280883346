var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.film.thumbnail)?_c('div',{staticClass:"Carousel__card Carousel__card--film relative w-100 mt3 ph3",class:{ 'filmCard__active': _vm.isActive }},[_c('router-link',{staticClass:"db pointer no-underline",attrs:{"to":{
      path: `/${_vm.getLocale()}/${_vm.l(
        'filmsSlug', _vm.getLocale()
      )}/${_vm.film.slug}`
    },"tag":"a"}},[_c('div',{class:`Carousel__img-wrapper Carousel__img-wrapper--fixed-aspect
     relative ${_vm.isActive ? 'ba b--white bw2 filmCard__active--img' : 'Carousel__card--img'}`},[_c('ResponsiveImage',{attrs:{"src":_vm.film.thumbnail || null,"canvasWidth":"330","classes":"Carousel__img absolute w-100"}}),(_vm.isActive)?_c('playIcon',{staticClass:"Carousel__img-play absolute"}):_vm._e()],1),_c('p',{class:`small source-sans-pro mt2
              ${_vm.isActive ? 'white relative' : 'eti-gray'}`},[_vm._v(" "+_vm._s(_vm.film.title)+" ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }